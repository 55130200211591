import { trackEvent } from "services/moengage.service";
import nirog_partner_moengage from "../../assets/mo_engage/nirog_partner.json";

const events = nirog_partner_moengage.entity;

export const onClickNirogPartner = (callback = () => { }, attributes = {}) => {
    trackEvent(events.NP_QUICK_LINK_NIROG_PARTNER.event_name, attributes);
    callback();
};
//Dashboard events
export const onClickMyEarnings = (callback = () => { }, attributes = {}) => {
    trackEvent(events.NP_Earnings_Click.event_name, attributes);
    callback();
};

export const onClickAddBankDetails = (callback = () => { }, attributes = {}) => {
    trackEvent(events.NP_KYC_View_Click.event_name, attributes);
    callback();
};

export const onClickSaveBankDetails = (callback = () => { }, attributes = {}) => {
    trackEvent(events.NP_SAVE_KYC_DETAILS.event_name, attributes);
    callback();
};

export const onClickOkayWhenKYCUnderProgress = (callback = () => { }, attributes = {}) => {
    trackEvent(events.NP_KYC_UNDER_PROGRESS.event_name, attributes);
    callback();
};

export const onClickReuploadWhenKYCRejected = (callback = () => { }, attributes = {}) => {
    trackEvent(events.NP_KYC_REJECTED.event_name, attributes);
    callback();
};

export const onClickAboutCallNow = (callback = () => { }, attributes = {}) => {
    trackEvent(events.NP_ABOUT_US_CALL_NOW.event_name, attributes);
    if (typeof callback === "function") {
        callback();
    }
};

export const onClickAboutEmail = (callback = () => { }, attributes = {}) => {
    trackEvent(events.NP_ABOUT_US_EMAIL_ID.event_name, attributes);
    if (typeof callback === "function") {
        callback();
    }
};

//OrderForPatient
export const onClickOrderForPatient = (callback = () => { }, attributes = {}) => {
    trackEvent(events.NP_Patient_Order_Click.event_name, attributes);
    callback();
};

export const onSearchForSearchBar = (callback = () => { }, attributes = {}) => {
    trackEvent(events.NP_SEARCH_BAR.event_name, attributes);
    callback();
};

export const onClickSelectMedicine = (callback = () => { }, attributes = {}) => {
    trackEvent(events.NP_Select_Medicine_Click.event_name, attributes);
    callback();
};

export const onClickSelectSizeAndQuantity = (callback = () => { }, attributes = {}) => {
    trackEvent(events.NP_Select_Quantity_Continue.event_name, attributes);
    callback();
};

export const onSetPatientDiscountAddToCart = (callback = () => { }, attributes = {}) => {
    trackEvent(events.NP_Select_Discount_Continue.event_name, attributes);
    callback();
};

//Patient Cart
export const onChangeQuantity = (callback = () => { }, attributes = {}) => {
    trackEvent(events.NP_CHANGE_QUANTITY_MY_CART.event_name, attributes);
    callback();
};

export const onClickProceedToPrescription = (callback = () => { }, attributes = {}) => {
    trackEvent(events.NP_MY_CART_PROCEED.event_name, attributes);
    callback();
};

export const onClickUploadManualPrescription = (callback = () => { }, attributes = {}) => {
    trackEvent(events.NP_UPLOAD_MANUAL_PRESCRIPTION.event_name, attributes);
    callback();
};

export const onClickViewManualPrescription = (callback = () => { }, attributes = {}) => {
    trackEvent(events.NP_VIEW_MANUAL_PRESCRIPTION.event_name, attributes);
    callback();
};

export const onClickProceedAfterManualPrescription = (callback = () => { }, attributes = {}) => {
    trackEvent(events.NP_PROCEED_MANUAL_PRESCRIPTION.event_name, attributes);
    callback();
};

export const onClickUploadDigitalPrescription = (callback = () => { }, attributes = {}) => {
    trackEvent(events.NP_UPLOAD_DIGITAL_PRESCRIPTION.event_name, attributes);
    callback();
};

export const onClickProceedAfterDigitalPrescription = (callback = () => { }, attributes = {}) => {
    trackEvent(events.NP_PROCEED_DIGITAL_PRESCRIPTION.event_name, attributes);
    callback();
};

export const onClickSharePatientPaymentLink = (callback = () => { }, attributes = {}) => {
    trackEvent(events.NP_SHARE_LINK_PATIENT_PAYMENT_PAGE.event_name, attributes);
    callback();
};

export const onClickSharePaymentLink = (callback = () => { }, attributes = {}) => {
    trackEvent(events.NP_SHARE_LINK_PATIENT_SHARE.event_name, attributes);
    callback();
};

export const onClickBackSharePaymentLink = (callback = () => { }, attributes = {}) => {
    trackEvent(events.NP_SHARE_LINK_PATIENT_BACK.event_name, attributes);
    callback();
};

export const onClickPayNow = (callback = () => { }, attributes = {}) => {
    trackEvent(events.NP_PAY_NOW_PAYMENT.event_name, attributes);
    callback();
};

export const onSelectCodPaymentOption = (callback = () => { }, attributes = {}) => {
    trackEvent(events.NP_COD_PAYMENT_MODE.event_name, attributes);
    callback();
};

export const onSelectOnlinePaymentOption = (callback = () => { }, attributes = {}) => {
    trackEvent(events.NP_PAY_ON_LINE_PAYMENT_MODE.event_name, attributes);
    callback();
};

export const onOrderConfirmation = (callback = () => { }, attributes = {}) => {
    trackEvent(events.NP_ORDER_CONFIRMATION_PAGE.event_name, attributes);
    callback();
};

//Order Details
export const onClickOrderDetails = (callback = () => { }, attributes = {}) => {
    trackEvent(events.NP_Orders_Click.event_name, attributes);
    callback();
};

export const onViewTrackOrder = (callback = () => { }, attributes = {}) => {
    trackEvent(events.NP_TRACK_ORDER.event_name, attributes);
    callback();
};

export const onClickRealtimeTracking = (callback = () => { }, attributes = {}) => {
    trackEvent(events.NP_TRACK_REALTIME.event_name, attributes);
    callback();
};

export const onClickDownloadPrescription = (callback = () => { }, attributes = {}) => {
    trackEvent(events.NP_DOWNLOAD_PRESCRIPTION.event_name, attributes);
    callback();
};

export const onClickDownloadInvoice = (callback = () => { }, attributes = {}) => {
    trackEvent(events.NP_DOWNLOAD_INVOICE.event_name, attributes);
    callback();
};

export const onClickPlacedOrders = (callback = () => { }, attributes = {}) => {
    trackEvent(events.NP_ACTIVE_ORDER.event_name, attributes);
    callback();
};

export const onClickDraftOrders = (callback = () => { }, attributes = {}) => {
    trackEvent(events.NP_DRAFT_ORDER.event_name, attributes);
    callback();
};

export const onClickPlacedOrderForDetails = (callback = () => { }, attributes = {}) => {
    trackEvent(events.NP_ORDER_DETAIL_PAGE_ACTIVE_ORDER.event_name, attributes);
    callback();
};

export const onClickDraftOrderForDetails = (callback = () => { }, attributes = {}) => {
    trackEvent(events.NP_ORDER_DETAIL_PAGE_DRAFT_ORDER.event_name, attributes);
    callback();
};

export const onClickSearchOrderResellerOrders = (callback = () => { }, attributes = {}) => {
    trackEvent(events.NP_SEARCH_ORDER_LISTING_PAGE.event_name, attributes);
    callback();
}

export const onClickCartIcon = (callback = () => { }, attributes = {}) => {
    trackEvent(events.NP_CART_ICON_CLICK.event_name, attributes);
    callback();
};

//Search Add or Select patient and address
export const onClickSearchByMobile = (callback = () => { }, attributes = {}) => {
    trackEvent(events.NP_Search_Patient_Mobile_Next.event_name, attributes);
    callback();
};

export const onClickAddNewPatientWithNewMobile = (callback = () => { }, attributes = {}) => {
    trackEvent(events.NP_Add_Patient_Click.event_name, attributes);
    callback();
};

export const onClickSaveNewPatientWithNewMobile = (callback = () => { }, attributes = {}) => {
    trackEvent(events.NP_Add_Patient_Confirm.event_name, attributes);
    callback();
};

export const onClickProceedAfterSelectedPatient = (callback = () => { }, attributes = {}) => {
    trackEvent(events.NP_Select_Patient_Name_Next.event_name, attributes);
    callback();
};

export const onClickAddPatientByExistingMobile = (callback = () => { }, attributes = {}) => {
    trackEvent(events.NP_Add_Patient_Name_Click.event_name, attributes);
    callback();
};

export const onClickSaveNewPatientByExistingMobile = (callback = () => { }, attributes = {}) => {
    trackEvent(events.NP_Add_Patient_Name_Confirm.event_name, attributes);
    callback();
};

export const onClickProceedAfterSelectAddress = (callback = () => { }, attributes = {}) => {
    trackEvent(events.NP_Select_Patient_Address_Next.event_name, attributes);
    callback();
};

export const onClickAddNewAddressForPatient = (callback = () => { }, attributes = {}) => {
    trackEvent(events.NP_Add_Patient_Address_Click.event_name, attributes);
    callback();
};

export const onClickSaveNewAddressForPatient = (callback = () => { }, attributes = {}) => {
    trackEvent(events.NP_Save_Patient_Address_Click.event_name, attributes);
    callback();
};
export const addToCartClick = () => {
    trackEvent(events.NP_Cart_Icon_Click.event_name, {});

}